import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import Photo from './Photo'

const Partner = ({
  part: {
    acf: { name, photo, description },
  },
}) => {
  return (
    <S.CardPartner>
      <Photo image={photo} />
      <S.PartnerName>{name}</S.PartnerName>
      <S.PartnerDescription>{description}</S.PartnerDescription>
    </S.CardPartner>
  )
}

Partner.propTypes = {
  part: PropTypes.shape({
    acf: PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.object,
      description: PropTypes.string,
    }),
  }).isRequired,
}

export default Partner
