import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Partners from 'components/partners'

const PartnersPage = ({ data, location, pageContext }) => {
  const wordpressData = data.allWordpressAcfPartnerCustomPost.nodes
  const wordpressPage = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO
        title={wordpressPage.title}
        description={wordpressPage.subtitle}
        canonical={wordpressPage.canonical}
        pathName={location.pathname}
      />
      <Partners 
        pageData={wordpressPage} 
        partnersData={wordpressData}
      />
    </Layout>
  )
}

export default PartnersPage

PartnersPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        canonical
        demonstrative_text
        complementary_text
        partners_title
        partners_img {
          alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
        }
      }
    }
    allWordpressAcfPartnerCustomPost {
      nodes {
        acf {
          name
          description
          photo {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
