import styled from 'styled-components'
import media from 'styled-media-query'
import { ContainerDasa } from 'components/shared/container'
import { alvTokens } from '@dasa-health/alma-tokens'

export const PartnersWrapper = styled(ContainerDasa)`
`

export const PartnerDescriptionWrapper = styled.div`
  margin-bottom: 5.6rem;
  
  ${media.lessThan('medium')`
    margin-bottom: 2.4rem;
  `}
`

export const PartnerTitle = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 2.4rem 0;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 0 0 2.4rem;
  `}
`

export const PartnerSubtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${alvTokens.ColorPaletteGray30};
  line-height: 170%;
  margin: 0;
  max-width: 75.9rem;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`

export const PartnersCardsContainer = styled.div`
  padding: 4.8rem 0 15.6rem;
  background-color: ${props => props.theme.colors.brand.primary[10]};

  ${media.lessThan('medium')`
    padding: 3.2rem 0;
  `}
`

export const PartnersCards = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  position: relative;
`

export const PartnerCardsContent = styled(ContainerDasa)``

export const PartnerCardTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  margin: 0 0 3.2rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const CardContainer = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-wrap: wrap;

  ${media.lessThan('medium')`
    align-items: center;
    justify-content: center;
  `}
`

export const PartnerImg = styled.div`
  position: absolute;
  right: 0;
  bottom: -47px;
  min-width: 50.8rem;
  max-height: 45.1rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`
