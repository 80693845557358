import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import Partner from './Partner'
import Img from 'gatsby-image'
import PinkLine from '../shared/PinkLine'

const Partners = ({ pageData, partnersData }) => {
  return (
    <>
      <S.PartnersWrapper>
        <S.PartnerDescriptionWrapper>
          <S.PartnerTitle>
            {pageData.title}
          </S.PartnerTitle>

          <S.PartnerSubtitle>
            {pageData.subtitle}
          </S.PartnerSubtitle>

        </S.PartnerDescriptionWrapper>
      </S.PartnersWrapper>

      <S.PartnersCardsContainer>
        <S.PartnersCards>
          <S.PartnerCardsContent>
            <S.PartnerCardTitle>
              {pageData.partners_title}
            </S.PartnerCardTitle>

            <S.CardContainer>
              {partnersData.map((part, index) => (
                <Partner key={index} part={part} />
              ))}
            </S.CardContainer>
          </S.PartnerCardsContent>

          <S.PartnerImg>
            <Img
              fluid={pageData.partners_img.localFile.childImageSharp.fluid}
              alt={pageData.partners_img.alt_text}
            />
          </S.PartnerImg>
        </S.PartnersCards>
      </S.PartnersCardsContainer>
      <PinkLine />
    </>
  )
}

Partners.propTypes = {
  pageData: PropTypes.object,
  partnersData: PropTypes.object,
}
export default Partners
