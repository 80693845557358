import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Photo = ({ className, image }) => {
  const {
    localFile: {
      childImageSharp: { fluid },
    },
  } = image
  return <S.Photo className={className} fluid={fluid} />
}

Photo.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
}

export default Photo
