import styled from 'styled-components'
import { Subtitle } from '../../shared/text-styled'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const CardPartner = styled.div`
  max-width: 26.3rem;
  height: auto;
  background-color: var(--white);
  border: 1px solid rgba(22, 22, 22, 0.24);
  border-radius: 8px;
  padding: 2.4rem;
  z-index: 15;

  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`
export const PartnerName = styled.h3`
  color: ${alvTokens.ColorPaletteGray30};
  font-size: 16px;
  margin: 1.6rem 0;
`

export const PartnerDescription = styled(Subtitle)`
  margin: 0;
`
