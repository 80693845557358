import styled from 'styled-components'
import media from 'styled-media-query'

export const Title = styled.h1`
  font-size: 40px;
  max-width: 75.9rem;
  color: ${props => props.theme.colors.brand.primary[10]};
  letter-spacing: -1px;
  font-weight: 700;
  line-height: 120%;
  margin: 0;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
  `}
`
export const Heading2 = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: var(--pink);
  letter-spacing: 1px;
`

export const Heading3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
  color: var(--pink);
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const Subtitle = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.typeface.smooth};
  font-weight: 400;
  line-height: 170%;
  margin: 2.4rem 0 3.2rem;
  max-width: 75.9rem;
  
  ${media.lessThan('medium')`
    margin: 2.4rem 0;
  `}
`

export const SpecializationArea = styled.p`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--pink);
  margin: 0 0 .8rem 0;
  line-height: 133%;
  letter-spacing: 1.5px;

  ${media.lessThan('medium')`
    margin-bottom: .8rem;
  `}
`

export const Synonymies = styled.p`
  color: ${props => props.theme.colors.typeface.smooth};
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  margin: 0;
  
  ${media.lessThan('medium')`
    font-size: 12px;
  `}
`

export const Info = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.palette.black};
  font-weight: 400;
  line-height: 170%;
  margin: ${props => props.noMargin ? "0" : "0 0 .8rem"};
`

export const SampleTypeItem = styled(Info)`
  color: ${props => props.theme.colors.typeface.smooth};
  &:last-child {
    margin: 0;
  }
`

export const InfoExam = styled(Info)`
  color: ${props => props.theme.colors.typeface.smooth};

  &:last-child {
    margin: 0;
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`

export const SubTitleDasa = styled.p`
  color: ${props => props.theme.colors.brand.primary[10]};
  font-size: 64px;
  font-weight: 700;
  line-height: 125%;
`